import React from 'react';
import Loadable from 'react-loadable';
import PrivateProvider from '@/components/providers/PrivateProvider';
import { LoadingPage } from '@/components/loading/loadingBase';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const ManagerLoadable = Loadable({
  loader: () => import('@/sections/Manager'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const ManagerPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Manager" />
      <PrivateProvider>
        <ManagerLoadable />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default ManagerPage;
